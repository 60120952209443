@import '../../global';

.ChatPane {
  height: calc(100% - 32px);
  padding: 16px;

  .ChatTranscript {
    height: calc(100% - 32px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ChatInput {
    margin-top: 24px;
    margin-left: 32px;
    height: 32px;

    input {
      margin: 0;
      padding: 0;
      width: 100%;
      border-bottom: 1px solid black;
      font-size: large;
    }
  }

  .ChatHeader {
    padding-bottom: 4px;
    margin-bottom: 16px;
    margin-top: 16px;
    border-bottom: 1px dashed $greyest;
  }

  .ChatMessage {
    margin-top: 8px;
    margin-left: 16px;
  }

  .Sprite {
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
  }

  .Name {
    width: calc(100% - 64px);
    display: inline-block;
    margin-left: 16px;
    vertical-align: bottom;
    overflow: hidden;
  }
}