@import '../../global';

.LandingPage {
  background-color: $white;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;

  .Hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    background-size: cover;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,0.65) 100%), url('/hero.jpg') no-repeat center center scroll;

    h1 {
      font-style: normal;
      font-weight: bold;
      color: #eee;
      font-size: 5em;
      letter-spacing: 0.03em;
      line-height: 1;
      text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.8);
      margin-bottom: 40px;
    }
  }

  .Intro {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 32px;
  }

  .Demo {
    flex: 1;
    text-align: center;
  }

  .DemoImage {
    height: 100%;
    max-height: 600px;
    padding: 16px;
  }

  .Description {
    font-size: 1.25em;
    flex: 1;
    b {
      color: $redder;
    }
  }

  .Steps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .Step {
    position: relative;
    flex: 1;
    border: 2px $grey solid;
    border-radius: 8px;
    padding: 24px 16px;
    margin: 16px;
    min-width: 300px;
  }

  .JoinButtonContainer {
    text-align: center;
    margin-top: 48px;
  }

  .Password {
    display: flex;
  }

  .LockIcon {
    margin-top: 16px;
    width: 46px;
    height: 46px;
  }

  p {
    margin-top: 16px;
  }


  label {
    position: absolute;
    font-weight: bold;
    top: -8px;
    left: 16px;
    background: $white;
    padding-left: 16px;
    padding-right: 16px;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  button {
    box-shadow:inset 0 1px 0 0 #ffffff;
    background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
    background-color: $white;
    border-radius:6px;
    border:1px solid $greyer;
    display:inline-block;
    cursor:pointer;
    color:#666666;
    font-size:20px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0 1px 0 $white;
  }

}