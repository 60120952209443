@import '../../global';

.MeetingView {
  width: 100vw;
  height: 100vh;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .VideosContainer {
    width: 100%;
    margin-top: 24px;
    height: calc(100% - 24px);
    display: grid;
  }

  // ----- BACK TO MAP -----

  .MapModeButton {
    @extend .Button;
    position: absolute;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba($grey, $grey_opacity);
    cursor: pointer;
  }

  .MapModeButton:hover {
    text-decoration: underline;
  }

  .MapIconContainer {
    height: 24px;
    width: 24px;
  }

  .MapIconText {
    @extend .unselectable;
    font-size: small;
    margin-left: 16px;
  }

}