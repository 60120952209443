@import '../../global';

.VideoPane {
  position: relative;
  display: inline-block;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .NameRow {
    position: absolute;
    left: 8px;
    top: 8px;
    width: calc(100% - 16px);
    z-index: 25;
    text-align: center;
  }

  .Mirrored {
    transform: scale(-1, 1);
    filter: flipH();
  }

  .SpinnerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: $black;
    text-align: left;
  }

  .Spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: calc(50% - 27px);
  }

  .SpriteOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: $black;
  }

  .PersonSprite {
    position: absolute;
    width: 32px;
    height: 32px;
  }

  .MutedIndicator {
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 100;
    bottom: 8px;
    right: 8px;
    border-radius: 50%;
    background-color: rgba($red, $grey_opacity);
    fill: $white;
  }
}
