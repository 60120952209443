@import '../../global';
@import '../MapView/MapView';

$border_width: 2px;
$padding: 0px;
$margin: 8px;

.CharacterSpriteSelector {
  height: 2 * 32px + $margin * 4;

  .Row {
    clear: both;
    height: max-content;
    width: max-content;
    margin: 0 auto;
  }

  .Preview {
    margin: $margin - $padding;
    padding: $padding;
    float: left;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .Unselected:hover {
    margin-bottom: $margin - $padding - $border_width;
    border-bottom: $border_width $greyer solid;
  }

  .Selected {
    margin: $margin - $padding - $border_width;
    border: $border_width $red solid;
  }

  .AnimateF0:hover {
    animation: f0 1s steps(4) infinite;
    @keyframes f0 { from { background-position: 0 -448px; } to { background-position: -128px -448px; } }
  }

  .AnimateF1:hover {
    animation: f1 1s steps(4) infinite;
    @keyframes f1 { from { background-position: 0 -128px; } to { background-position: -128px -128px; } }
  }

  .AnimateF2:hover {
    animation: f2 1s steps(4) infinite;
    @keyframes f2 { from { background-position: 0 -96px; } to { background-position: -128px -96px } }
  }

  .AnimateF3:hover {
    animation: f3 1s steps(4) infinite;
    @keyframes f3 { from { background-position: 0 -256px; } to { background-position: -128px -256px; } }
  }

  .AnimateF4:hover {
    animation: f4 1s steps(4) infinite;
    @keyframes f4 { from { background-position: 0 -192; } to { background-position: -128px -192px; } }
  }

  .AnimateF5:hover {
    animation: f5 1s steps(4) infinite;
    @keyframes f5 { from { background-position: 0 -320px; } to { background-position: -128px -320px; } }
  }

  .AnimateF6:hover {
    animation: f6 1s steps(4) infinite;
    @keyframes f6 { from { background-position: 0 -384px; } to { background-position: -128px -384px } }
  }

  .AnimateM0:hover {
    animation: m0 1s steps(4) infinite;
    @keyframes m0 { from { background-position: 0 -480px; } to { background-position: -128px -480px; } }
  }

  .AnimateM1:hover {
    animation: m1 1s steps(4) infinite;
    @keyframes m1 { from { background-position: 0 0; } to { background-position: -128px 0 } }
  }

  .AnimateM2:hover {
    animation: m2 1s steps(4) infinite;
    @keyframes m2 { from { background-position: 0 -32px; } to { background-position: -128px -32px } }
  }

  .AnimateM3:hover {
    animation: m3 1s steps(4) infinite;
    @keyframes m3 { from { background-position: 0 -224px; } to { background-position: -128px -224px } }
  }

  .AnimateM4:hover {
    animation: m4 1s steps(4) infinite;
    @keyframes m4 { from { background-position: 0 -64px; } to { background-position: -128px -64px } }
  }

  .AnimateM5:hover {
    animation: m5 1s steps(4) infinite;
    @keyframes m5 { from { background-position: 0 -288px; } to { background-position: -128px -288px } }
  }

  .AnimateM6:hover {
    animation: m6 1s steps(4) infinite;
    @keyframes m6 { from { background-position: 0 -352px; } to { background-position: -128px -352px } }
  }
}