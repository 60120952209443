@import '../../global';

.ConferenceBlade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 50;
  float: left;
  text-align: center;
  padding-bottom: 32px;

  .MeetingModeButton {
    @extend .Button;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: rgba($grey, $grey_opacity);
    border-radius: 8px;
    cursor: pointer;
    visibility: hidden;
  }

  .IconContainer {
    height: 32px;
    width: 32px;
  }

  .IconText {
    @extend .unselectable;
  }
}

.ConferenceBlade:hover .MeetingModeButton {
  visibility: visible;
}
