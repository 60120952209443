@import url('https://fonts.googleapis.com/css2?family=Oxygen+Mono&display=swap');
@import './global.scss';

$field_margin_top: 1em;
$field_margin_to_label: 0.25em;
$field_line_height: 1.45em;

input {
  // Some basic sizing
  width: 100%;
  max-width: 1000px;
  padding-left: 7px;
  margin-top: $field_margin_top;
  margin-bottom: $field_margin_to_label;
  // The single underline styling
  border: none;
  border-bottom: 1px solid $greyer;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  display: block;
  background: transparent;
  // Font/text styling
  font-size: $field_line_height;
  letter-spacing: 0.04em;
  line-height: 1.25em;
  // Automatic resizing
  resize: none;
  overflow: hidden;
}

input:focus {
  border-bottom-color: $red;
}

.AppContainer {
  width: 100vw;
  height: 100vh;
  background: black;
  font-family: "Oxygen Mono", "Courier new", Courier, monospace;
  font-size: 1.1em;
}
