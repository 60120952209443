@import '../../global';
@import '../MapView/MapView';

$settings_padding: 32px;
$section_padding: 16px;

.SettingsView {
  position: fixed;
  left: calc(20vw - #{$settings_padding});
  top: calc(25vh - #{$large_icon_radius + 2 * $icon_margin / 2 + $settings_padding});
  width: 60vw;
  height: 50vh;
  background: $white;
  border-radius: 64px;
  padding: $settings_padding;

  .SettingsList {
    overflow-y: auto;
    height: calc(100% - 64px);
  }

  .Pane {
    position: relative;
    border: 2px $grey solid;
    border-radius: 8px;
    padding: 16px;
    margin-top: 48px;
    margin-bottom: 32px;
    margin-right: 8px;

    label {
      font-weight: bold;
      position: absolute;
      top: -$section_padding + 5px;
      left: $section_padding;
      background: $white;
      padding-left: $section_padding / 2;
      padding-right: $section_padding / 2;
    }
  }

  /* ----- TITLE ----- */
  h1, h2, h3 {
    text-align: center;
    padding: 0;
    margin: 0 0 16px;
  }

  /* ----- SPRITE SELECTOR ----- */

  .SpriteSelectorContainer {
    margin: 0 auto;
  }

  /* ----- CLOSE BUTTON ----- */
  .CloseButton {
    position: absolute;
    top: 32px;
    right: 48px;
    font-family: sans-serif;
    font-size: 32px;
    cursor: pointer;
  }

  .CloseButton:hover {
    color: $redder;
  }
}

.SettingsView::-webkit-scrollbar {
  display: none;
}

/* ----- VEIL ----- */

.SettingsVeil {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
