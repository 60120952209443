@import '../../global.scss';

.LobbyView {
  position: fixed;
  top: calc(50% - 75px);
  left: 20vw;
  width: 60vw;
  background: $white;
  border-radius: 64px;
  padding: 48px 32px;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 100;

  .HiddenRoomName {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
  }

  .Password {
    display: flex;
  }

  .LockIcon {
    margin-top: 16px;
    width: 46px;
    height: 46px;
  }

  .InputContainer {
    width: 100%
  }

  .ButtonRow {
    clear: both;
    margin-top: 32px;
    width: 100%;
    text-align: right;
  }

  button {
    box-shadow:inset 0 1px 0 0 #ffffff;
    background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
    background-color: $white;
    border-radius:6px;
    border:1px solid $greyer;
    display:inline-block;
    cursor:pointer;
    color:#666666;
    font-size:20px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0 1px 0 $white;
  }

  button:hover {
    background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
    background-color:#f6f6f6;
  }

  button:active {
    position:relative;
    top:1px;
  }

}
