
$white: #f0f0f0;
$grey: #cdcdcd;
$greyer: #bebebe;
$greyest: #454545;
$black: #1e1e1e;
$green: #2c9c2c;
$red: #d24242;
$redder: #b41717;
$shadow: #373737;

$grey_opacity: 0.9;

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* standard */
}

/* ----- NAME TAG ----- */

.NameTag {
  @extend .unselectable;
  display: inline-block;
  background-color: rgba($grey, $grey_opacity);
  border-radius: 4px;
  border: 1px solid $greyest;
  padding: 2px 4px;
  z-index: 25;
  overflow: hidden;
  font-size: small;
}

.NameTagHere::before {
  content: '⬤';
  padding-right: 4px;
  font-size: small;
  height: 100%;
  color: #1e711e;
}

.NameTagAway::before {
  content: '⬤';
  padding-right: 4px;
  font-size: small;
  height: 100%;
  color: #ce7a1a;
}

/* ----- BUTTON ----- */

.Button {
  z-index: 1000;
  border: 1px solid $greyest;
}

.Button:hover {
  box-shadow: 0 0 12px black;
}

.Button:active {
  box-shadow: 0 0 8px black;
}
