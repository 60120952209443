
.CharacterList {

  ul {
    margin: 32px 16px;
    padding: 0;
    -webkit-padding-start: 0;
  }

  li {
    list-style-type: none;
    margin-bottom: 16px;
  }

  .Sprite {
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
  }

  .Name {
    width: calc(100% - 64px);
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
    overflow: hidden;
  }

}