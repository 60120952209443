/* ----- ICON VARIABLES ----- */

$large_icon_radius: 32px;
$large_icon_padding: 16px;
$small_icon_radius: 24px;
$small_icon_padding: 8px;
$square_icon_padding: 8px;
$icon_margin: 32px;

$large_bottom: $icon_margin;
$small_bottom: $large_bottom + $large_icon_radius - $small_icon_radius + $large_icon_padding - $small_icon_padding;
$square_bottom: $small_bottom + $small_icon_radius / 2;

$video_left: $large_icon_radius + $large_icon_padding;
$audio_left: $video_left + $icon_margin + ($small_icon_radius+$small_icon_padding)*2;
$online_left: $audio_left + $icon_margin + ($small_icon_radius+$small_icon_padding)*2;
$settings_left: $icon_margin + $large_icon_radius + $large_icon_padding;
$chat_left: $settings_left + $icon_margin + ($small_icon_radius+$small_icon_padding)*2;

/* ----- VIDEO ICON ----- */

.VideoIcon {
  @extend .Button;
  position: absolute;
  width: $large_icon_radius * 2;
  height: $large_icon_radius * 2;
  bottom: $large_bottom;
  padding: $large_icon_padding;
  left: calc(50% - #{$video_left});
  border-radius: 50%;
  cursor: pointer;
}

.VideoOn {
  background-color: rgba($grey, $grey_opacity);
  fill: $black;
}

.VideoOff {
  background-color: rgba($red, $grey_opacity);
  fill: $white;
}

.VideoOff:hover {
  background-color: rgba($red, $grey_opacity);
  box-shadow: 0 0 12px black;
}

/* ----- AUDIO ICON ----- */

.AudioIcon {
  @extend .Button;
  position: absolute;
  width: $small_icon_radius * 2;
  height: $small_icon_radius * 2;
  bottom: $small_bottom;
  padding: $small_icon_padding;
  left: calc(50% - #{$audio_left});
  border-radius: 50%;
  cursor: pointer;
}

.AudioOn {
  background-color: rgba($grey, $grey_opacity);
  fill: $black;
}

.AudioOff {
  background-color: rgba($red, $grey_opacity);
  fill: $white;
}

.AudioOff:hover {
  box-shadow: 0 0 12px black;
}

/* ----- SETTINGS ICON ----- */

.SettingsIcon {
  @extend .Button;
  position: absolute;
  width: $small_icon_radius * 2;
  height: $small_icon_radius * 2;
  bottom: $small_bottom;
  padding: $small_icon_padding;
  left: calc(50%  + #{$settings_left});
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba($grey, $grey_opacity);
  fill: $black;
}
