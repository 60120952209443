@import '../../global';
@import '../../core/controls';

$animation_speed: 0.1s;

.MapView {

  /* ----- ONLINE USERS ----- */

  .OnlineUsers {
    position: absolute;
    bottom: $square_bottom;
    padding: $square_icon_padding;
    border: 1px solid $greyest;
    border-radius: 8px;
    background-color: rgba($grey, $grey_opacity);
    fill: $black;
    overflow: hidden;
    left: calc(50% - #{$online_left});
    width: $small_icon_radius * 2;
    height: $small_icon_radius;

    -webkit-transition: all $animation_speed ease-in-out;
    -moz-transition: all $animation_speed ease-in-out;
    -o-transition: all $animation_speed ease-in-out;
    -ms-transition: all $animation_speed ease-in-out;
    transition: all $animation_speed ease-in-out;

    .OnlineIconContainer {
      position: absolute;
      height: 24px;
      width: 24px;
      right: ($small_icon_radius - $square_icon_padding) * 2;
      bottom: $square_icon_padding;
    }

    .OnlineCount {
      @extend .unselectable;
      position: absolute;
      text-align: right;
      right: ($square_icon_padding + 2);
      bottom: $square_icon_padding;
    }
  }

  .OnlineUsers:hover {
    left: calc(50% - #{$online_left - $small_icon_radius * 2} - 256px);
    width: 256px;
    height: 512px;
  }

  /* ----- CHAT ----- */

  .Chat {
    position: absolute;
    bottom: $square_bottom;
    padding: $square_icon_padding;
    border-radius: 8px;
    border: 1px solid $greyest;
    overflow: hidden;
    left: calc(50% + #{$chat_left});
    width: $small_icon_radius * 2;
    height: $small_icon_radius;

    -webkit-transition: all $animation_speed ease-in-out;
    -moz-transition: all $animation_speed ease-in-out;
    -o-transition: all $animation_speed ease-in-out;
    -ms-transition: all $animation_speed ease-in-out;
    transition: all $animation_speed ease-in-out;
  }

  .ChatRead {
    background-color: rgba($grey, $grey_opacity);
    fill: black;

    .ChatIconContainer {
      position: absolute;
      left: 20px;
      bottom: $square_icon_padding;
      height: 24px;
      width: 32px;
    }
  }

  .ChatUnread {
    background-color: rgba($red, $grey_opacity);
    fill: white;

    .ChatIconContainer {
      position: absolute;
      left: 20px;
      bottom: 0;  // TODO(gabor): God knows why this is different in the unread condition :(
      height: 24px;
      width: 32px;
    }
  }

  .Chat:hover {
    left: calc(50% + #{$chat_left});
    width: 25%;
    height: 50%;
  }

}

