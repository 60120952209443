@import '../../global';

$border_radius: 16px;

.PersonSprite {
  position: fixed;
  width: 32px;
  height: 32px;
  z-index: 10;

  .Name {
    position: absolute;
    top: -32px;
    left: -64px;
    text-align: center;
    width: 160px;
    font-size: 0.8em;
    overflow: hidden;
  }
}